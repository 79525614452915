.page-cform {
  background: #ffffff;
  border-radius: 1%;
  margin: 20px auto 20px auto;
  padding: 20px;
}
input {
  margin: 3px 0 3px 0;
  text-align: center !important;
}
.total-cal {
  margin: 5px 0px 0px 0px;
  text-align: right;
  font-size: 17px;
}
.spin-pos {
  /* text-align: -webkit-center; */
  margin: 0% 50% 0% 50%;
}

.swapping-squares-spinner,
.swapping-squares-spinner * {
  box-sizing: border-box;
}

.swapping-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swapping-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width: calc(65px * 0.25 / 1.3);
  animation-duration: 1000ms;
  border: calc(65px * 0.04 / 1.3) solid var(--root-primary-color, #009ee0);
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  animation-iteration-count: infinite;
}

.swapping-squares-spinner .square:nth-child(1) {
  animation-name: swapping-squares-animation-child-1;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(2) {
  animation-name: swapping-squares-animation-child-2;
  animation-delay: 0ms;
}

.swapping-squares-spinner .square:nth-child(3) {
  animation-name: swapping-squares-animation-child-3;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(4) {
  animation-name: swapping-squares-animation-child-4;
  animation-delay: 0ms;
}

@keyframes swapping-squares-animation-child-1 {
  50% {
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-2 {
  50% {
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-3 {
  50% {
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-4 {
  50% {
    transform: translate(150%, -150%) scale(2, 2);
  }
}
button.add-client.btn.btn-secondary {
  border-radius: 50%;
  background: linear-gradient(
    -45deg,
    var(--new-client-btn),
    var(--new-client-btn-gdient)
  );
  border: 1px solid;
  z-index: 999999;
}
.big-btn {
  width: 70px;
  height: 70px;
}
button.custom.btn.btn-secondary {
  background: var(--main-col);
  border: 1px solid var(--main-bor);
}
.modal-btn {
  background: unset;
  border: unset;
  z-index: 999999;
  color: var(--root-secondary-color, #294aec);
}
.modal-btn:hover {
  background: #e8e8e8 !important;
  color: var(--root-secondary-color, #294aec);
}

.form-control:focus {
  border-color: #80bdff;
}

.form-control {
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 236, 0.26);
}
.form-group {
  margin-bottom: 0px;
}
.headerName {
  color: var(--main-col);
}
.back-to-client,
.back-to-client:focus,
.back-to-client:active {
  background: var(--main-col) !important;
  border-color: var(--main-bor) !important;
}
.back-to-client:hover {
  background: var(--root-secondary-border-color, #294aeced) !important;
  border-color: var(--root-secondary-border-color, #294aeced) !important;
}
.no-bg-warning,
.no-bg-warning:active,
.no-bg-warning:focus {
  background-color: transparent !important;
  border: unset !important;
  font-size: 20px !important;
  padding: 0px !important;
  color: var(--building-icon) !important;
  box-shadow: unset !important;
}
.no-bg-warning:hover {
  color: var(--building-icon-hover) !important;
}

.no-bg-info,
.no-bg-info:active,
.no-bg-info:focus {
  background-color: transparent !important;
  border: unset !important;
  font-size: 20px !important;
  padding: 0px !important;
  color: var(--update-icon) !important;
  box-shadow: unset !important;
}
.no-bg-info:hover {
  color: var(--update-icon-hover) !important;
}

.no-bg-danger,
.no-bg-danger:active,
.no-bg-danger:focus {
  background-color: transparent !important;
  border: unset !important;
  font-size: 20px !important;
  padding: 0px !important;
  color: var(--delete-icon) !important;
  box-shadow: unset !important;
}
.no-bg-danger:hover {
  color: var(--delete-icon-hover) !important;
}
b.res-val {
  margin-right: 38%;
}
b.res-val-8 {
  margin-right: 0%;
}
.ta-4 {
  text-align: end;
  padding-top: 8px;
}
.fl {
  float: left;
}

.form-check-input {
  margin-left: unset !important;
}
.add-btn {
  position: fixed;
  bottom: 30px;
  right: 40px;
  border: 1px solid #989aecb0 !important;
  box-shadow: 0 5px 8px 0 rgba(75, 70, 70, 0.377),
    0 2px 10px 0 rgba(75, 68, 68, 0.075) !important;
}
/* .back-btn {
  position: fixed;
  right: 50px;
  top: 90px;
  z-index: 999;
} */
.append-percent {
  height: 38px;
  margin-top: 3px;
}
.append-percent span.input-group-text {
  background: #cdcdda;
}

body::-webkit-scrollbar {
  width: 1em;
}
body::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: #cdcdda;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.obj-select {
  max-width: 110px;
  font-size: 12px;
  font-weight: 700;
  /* position: absolute; */
  right: calc((100%) / 25);
}
.obj-action-icon {
  font-size: 20px !important;
}
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #f6f6f6ce;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #f6f6f600;
}

.custom-cont-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.cont-wrapper {
  margin-top: 95px;
}
.stickybtn {
  margin-left: 0px;
  margin-right: 0px;
  /* width: 88%; */
  position: fixed;
  margin-top: -20px;
  z-index: 1;
  transition: padding 0.1s ease;
}
.modal-dialog {
  margin: 30px auto !important;
}

Button[alt] {
  transition: all 1.3s ease-in-out;
}
Button[alt]:hover:after {
  content: attr(alt);
  position: absolute;
  left: -70px;
  z-index: 1;
  font-size: 10px;
  padding: 5px;
  background: #424242e6;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}
.page-cform table thead tr {
  background: #45454512 !important;
}
.updt-icon {
  cursor: pointer;
  color: #2948ff;
}
.stat-style {
  font-size: 11px;
  font-weight: 200;
  padding: 3px;
  border-radius: 3px;
}

.status-active{
  background: var(--obj-stat-act-bg);
  border: 1px solid var(--obj-stat-act-bor);
  color: var(--obj-stat-act-col);
}

.status-inactive{
  background: var(--obj-stat-inact-bg);
  border: 1px solid var(--obj-stat-inact-bor);
  color: var(--obj-stat-inact-col);
}

.inprog{
  background: var(--obj-prog-inprog-bg);
  border: 1px solid var(--obj-prog-inprog-bor);
  color: var(--obj-prog-inprog-col);
}
.Offered {
  background: var(--obj-prog-offer-bg);
  border: 1px solid var(--obj-prog-offer-bor);
  color: var(--obj-prog-offer-col);
}
.Accepted {
  background: var(--obj-prog-acc-bg);
  border: 1px solid var(--obj-prog-acc-bor);
  color: var(--obj-prog-acc-col);
}
.Dismissed,
.stat-active{
  background: var(--obj-prog-dis-bg);
  border: 1px solid var(--obj-prog-dis-bor);
  color: var(--obj-prog-dis-col);
}
.notSet,
.stat-active {
  background: var(--root-not-set-color, #e83e8c24);
  border: 1px solid var(--root-not-set-color, #e83e8cf5);
  color: var(--root-not-set-color, #e83e8cf5);
}

/* .inprog,
.status-inactive {
  background: rgba(251, 161, 163, 0.32);
  border: 1px solid rgba(0, 158, 224, 0.52);
  color: var(--root-inprogressTxt-colo, #009ee0);
}
.Offered {
  background: rgba(189, 129, 245, 0.32);
  border: 1px solid rgba(88, 11, 137, 0.52);
  color: #320454;
}
.Accepted {
  background: rgba(85, 186, 245, 0.21);
  border: 1px solid rgba(12, 70, 130, 0.52);
  color: rgba(16, 135, 154, 0.96);
}
.Dismissed,
.stat-active,
.status-active {
  background: rgba(154, 247, 152, 0.26);
  border: 1px solid rgba(16, 174, 13, 0.52);
  color: rgba(34, 113, 14, 0.96);
}
.notSet,
.stat-active {
  background: #e83e8c24;
  border: 1px solid #ea3f8d8a;
  color: #e83e8cf5;
} */

.obj-drop-btn .btn.btn-secondary {
  background: unset;
  color: #969696;
  border: unset;
}
.obj-drop-btn .btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: unset;
}
.obj-drop-btn .dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f2f2f2;
}
.action-list:active {
  color: #fff;
  background-color: #009ee0 !important;
  border-color: #009ee0 !important;
}
.obj-stat-wrapper {
  padding: 20px 20px 20px 0;
}
.obj-stat-wrapper ul li {
  padding: 0.75rem 1.25rem;
  margin-bottom: 6px;
  list-style: none;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  border-radius: 5px;
}
.obj-stat-wrapper ul li:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 1;
  cursor: pointer;
}
.obj-list-active,
.obj-list-active span {
  color: aliceblue !important;
}
.obj-stat-wrapper li span {
  float: right;
  font-weight: 600;
  color: #464645;
}
.obj-list-active.all {
  background: linear-gradient(
    -45deg,
    var(--obj-act-all-gdient),
    var(--obj-act-all)
  );
}
.obj-list-active.in-progress {
  background: -webkit-linear-gradient(
    135deg,
    var(--obj-act-inprog-gdient),
    var(--obj-act-inprog)
  );
  background: linear-gradient(
    -45deg,
    var(--obj-act-inprog-gdient),
    var(--obj-act-inprog)
  );
}
.obj-list-active.offered {
  background: -webkit-linear-gradient(
    135deg,
    var(--obj-act-offer-gdient),
    var(--obj-act-offer)
  );
  background: linear-gradient(
    -45deg,
    var(--obj-act-offer-gdient),
    var(--obj-act-offer)
  );
}
.obj-list-active.accepted {
  background: -webkit-linear-gradient(
    135deg,
    var(--obj-act-acc-gdient),
    var(--obj-act-acc)
  );
  background: linear-gradient(
    -45deg,
    var(--obj-act-acc-gdient),
    var(--obj-act-acc)
  );
}
.obj-list-active.dismissed {
  background: -webkit-linear-gradient(
    30deg,
    var(--obj-act-dis),
    var(--obj-act-dis-gdient)
  );
  background: linear-gradient(
    60deg,
    var(--obj-act-dis),
    var(--obj-act-dis-gdient)
  );
}
.obj-list-active.not-set {
  background-color: var(--root-primary-color) !important;
}
