.modal-dialog {
  margin: 133px auto;
}

.delete-box {
  border: 1px solid red;
  border-radius: 3px;
}
.toast.show {
  padding: 7px 0px 0px 15px;
  position: relative;
  border-radius: 12px;
  height: 110px;
  cursor: pointer;
  color: #2b2a2ae0;
  margin: auto;
}
.toast.show:hover .dashboard-details-Hover {
  width: 100%;
}
.dashboard-details-Hover {
  position: absolute;
  width: 2%;
  background: var(--box-hov-bg);
  height: 100%;
  top: 0px;
  left: 0px;
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 10px;
  border-bottom-right-radius:10px ;
}
.label-details {
  position: relative;
  transition: all 0.3s ease-in-out;
}
.toast.show:hover .label-details,
.toast.show:hover .dash-icon,
.toast.show:hover .dash-box-text {
  color: white;
}

.dash-icon {
  font-size: 60px;
  color: var(--icons);
  transition: all 0.3s ease-in-out;
}
.c-font {
  font-size: 25px;
  /* margin: -10px 0px 0px 60px; */
}
.dash-box-text {
  font-size: 16px;
  font-weight: 600;
}
.db-box {
  margin-top: 20px;
}
.custom-cont-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.active-box {
  top: -5px !important;
  background: var(--root-secondary-color, #294aec) !important;
  color: #fff !important;
  height: 10px !important;
  line-height: 18px !important;
  border-radius: 10px !important;
  display: inline-block !important;
  margin: 3px 3px 3px !important;
  min-width: 10px !important;
  overflow: hidden !important;
  padding: 0 3px !important;
  text-align: center !important;
  white-space: normal !important;
  /* font-size: 5px !important; */
  position: relative;
  float: right;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--root-secondary-color, #294aec);
  background-color: var(--root-secondary-color, #294aec);
}

.tooltips {
  position: relative;
}
.tooltips:before,
.tooltips:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
/* .tooltips:after {
	border-right: 6px solid transparent;
	border-bottom: 6px solid rgba(0,0,0,.75); 
  border-left: 6px solid transparent;
  content: '';
  height: 0;
    top: 20px;
    left: 20px;
  width: 0;
} */
.tooltips:before {
  background: rgba(0, 0, 0, 0.59);
  border-radius: 2px;
  color: #fff;
  content: attr(data-title);
  font-size: 11px;
  left: -85px;
  padding: 5px;
  top: -32px;
  border-radius: 5px;
  white-space: nowrap;
}

/* expand */
.tooltips.expand:before {
  transform: scale3d(0.2, 0.2, 1);
  transition: all 0.2s ease-in-out;
}
.tooltips.expand:after {
  transform: translate3d(0, 6px, 0);
  transition: all 0.1s ease-in-out;
}
.tooltips.expand:hover:before,
.tooltips.expand:hover:after {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.tooltips.expand:hover:after {
  transition: all 0.2s 0.1s ease-in-out;
}
