:root {

  --main-col: #009ee0fa;
  --main-bor:rgba(14, 154, 214, 0.98);
  --main-btn-col: #009ee0fa;
  --icons:#009ee0fa;
  --box-hov-bg:#009ee0fa;
  --main-text:#009ee0fa;
  --active-menu:#009ee0fa;
  --active-menu-border:#009ee0fa;
  --nav-menu-hover:#009ee0fa;
  --spinner-col:#009ee0fa;

  /* Client */
  /* icons and button */
  --building-icon:#ffc107;
  --building-icon-hover:#e0b228;
  --update-icon:#17a2b8;
  --update-icon-hover:#138496;
  --delete-icon:#cb3131;
  --delete-icon-hover:red;  
  --new-client-btn:#009ee0fa;
  --new-client-btn-gdient:#009de0e5;

  /* object Tab List*/

  --obj-act-all:rgb(9 133 185);
  --obj-act-all-gdient:rgb(3 107 150);
  --obj-act-inprog:#05af49;
  --obj-act-inprog-gdient:rgb(10 121 54);
  --obj-act-offer:rgb(143 4 243);
  --obj-act-offer-gdient:rgb(92 6 172);
  --obj-act-acc:#ce0486f5;
  --obj-act-acc-gdient:#7f0559;
  --obj-act-dis:#e42a2a;
  --obj-act-dis-gdient:#960d05;

  /* object Progress */
  --obj-prog-inprog-bg:#06ac4817;
  --obj-prog-inprog-bor:#05ab47;
  --obj-prog-inprog-col:#038035;

  --obj-prog-offer-bg:#bd81f552;
  --obj-prog-offer-bor:#5b0897;
  --obj-prog-offer-col:#5b0897;

  --obj-prog-acc-bg:#ce048713;
  --obj-prog-acc-bor:#ce0486f5;
  --obj-prog-acc-col:#a1046af5;

  --obj-prog-dis-bg:#e42a2a1a;
  --obj-prog-dis-bor:#e42a2a;
  --obj-prog-dis-col:#ac1212;

  /* object active/inactive here*/
  --obj-stat-act-bg:#06ac4817;
  --obj-stat-act-bor:#05ab47;
  --obj-stat-act-col:#04973f;

  --obj-stat-inact-bg:#8d8d8d17;
  --obj-stat-inact-bor:#989999;
  --obj-stat-inact-col:#a0a0a0;


  /* global css here*/
  --pagination-default:#e4e6e8a3;
  --pagination-border:#d5dae0;
  

}




* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h5,
h2,
h3 {
  margin: 0;
}

.card {
  border-radius: 2px !important;
}

.edit-icon {
  color: #cedb1f;
  cursor: pointer;
}

.trash-icon {
  color: #009ee0;
  cursor: pointer;
}

body,
#root {
  background-color: var(--root-bg-color, #f6f6f6ce);
  font-size: 0.835rem;
  line-height: 1.4 !important;
  height: 100vh !important;
}

a {
  color: #333;
  text-decoration: none !important;
}

.main-nav {
  z-index: 1000;
}

.main-box {
  margin-left: 165px;
  margin-top: 20px;
}

.main-box-full {
  margin-left: 68px;
  margin-top: 20px;
}

main {
  transition: all 450ms;
}
@media screen and (max-width: 1024px) {
  .main-box {
    margin-left: 0;
  }
}

@media print {
  .main-navbar,
  .sidenav,
  .report-head,
  .page-title,
  .selection,
  .ml-4.back-to-client.back-btn.shadow.custom.btn.btn-secondary,
  #cart #checkout,
  .add-client.add-btn.btn.btn-secondary {
    display: none;
  }

  .main-box {
    margin-left: 0;
  }

  .gege {
    display: block;
  }

  .report-table {
    background-color: var(--root-primary-color, #009ee0);
    color: white;
  }
}
.table-hover tbody tr:hover {
  box-shadow: 0 2px 4px 0 rgba(72, 68, 68, 0.075),
    0 2px 10px 0 rgba(72, 68, 68, 0.075) !important;
}
.login-input {
  text-align: left !important;
}

.sidebar .nav-item:hover:last-child {
  cursor: initial;
}

.sidebar .nav-item {
  width: 153px;
  transition: all 200ms ease;
}
/* #meow { */
/* background-image: url('./brand/main.jpg');
 background-size: cover;  */
/* } */

#main-scroll.loginsi .scroll-content #meow .container {
  flex-grow: 1;
}

.login-img-bg {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
}
#main_background {
  object-fit: cover;
  background-size: 100px 100px;
  width: 100%;
  height: 110%;
  opacity: 0.8;
  position: fixed;
  top: -5px;
  right: 0;
  left: 0;
  bottom: 0;
}

.meow-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.page-item.active .page-link {
  z-index: 3;
  color: black;
  background-color: var(--pagination-default);
  border-color: var(--pagination-border);
}
