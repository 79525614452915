/* Define variables for the root element */
:root {
  --root-bg-color: hsla(0, 0%, 96%, 0.808);
  --root-main-color: white;
  --root-primary-color: #009ee0;
  --root-primary-border-color: #009de0e5;
  --root-secondary-color: #294aec;
  --root-secondary-border-color: #3d5cf5ed;
  --root-tertiary-color: #ffc107;
  --root-tertiary-colorHover: #e0b228;

  /*Background color for 5 status */
  --root-status-gradient: rgba(49, 36, 36, 0.856);
  --root-allTxt-color: #2948ff;
  --root-inprogressTxt-color: #009ee0;
  --root-inprogressTxt-colorBack: #009de023;
  --root-offeredTxt-color: #320454;
  --root-offeredTxt-colorBack: #3304542a;
  --root-accepted-color: rgba(16, 135, 154, 0.96);
  --root-accepted-colorBack: rgba(16, 136, 154, 0.219);
  --root-dismissed-color: rgba(34, 113, 14, 0.96);
  --root-dismissed-colorBack: rgba(34, 113, 14, 0.123);
  --root-not-set-color: #e83e8cf5;
  --root-not-set-colorBack: #e83e8d3f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: 'Century Gothic' !important;
}
