
ul#navlist a
{
	font-weight: normal;
	text-decoration: none;
	text-transform: capitalize;
}

ul#navlist, ul#navlist ul, ul#navlist li
{
margin: 0px;
padding: 0px;
list-style-type: none;
}
#cart {
    height: 31px;
    width: 25%;
    float: right;
    display: table;
    font-size: 26px;
}
#cartText {
	color: #A2ABB2;
	margin-left: 35px;
	margin-top: 7px;
	margin-right: 2px;
	margin-bottom: 5px;
	width: 135px;
	font-size: 10pt;
	clear: right;
}

ul#checkout a
{
	font-weight: normal;
	text-decoration: none;
	text-transform: capitalize;
}

ul#checkout, ul#checkout li
{
margin: 0px;
padding: 0px;
list-style-type: none;
}

ul#checkout li {
	vertical-align: middle;
}



ul#itemList {
	padding: 15px;
	width: 279px;
	position: absolute;
	display: none;
    margin-bottom: 10px;
    font-size: 10pt;
    background-color: #ffffff;
    color: #313030;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    z-index: 9;
    border: 1px solid #b3b3b36e;
}
ul#itemList:hover{
    opacity: unset ;
}
ul#itemList li {
	display: inline-block;
    width: 100%;
    padding: 5px;
}
#itemList li:hover {
    background: #aaaaaa;
    color:#ffffff;
}
ul#checkout li:hover  ul#itemList{
    /* display: block; */
    font-size: 10pt;
    background-color: #2A343B;
    color: #CCC;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    z-index: 9;
    
}
.product-cart:hover {
    background-color: unset !important;
    color: #db1842;
}
.product-cart{
    color: #ef5777;
    display: initial !important;
}
.prod-item-img {
    width: 70px;
}
.load-more {
    background-color: unset !important;
}
.page-item.cart{
    float: unset !important;
}
.cart-pagination {
    display: inline-flex;
}
.custom-page-link {
    padding: 0.1rem .5rem !important;
}
.custom-pagination {
    display: inline-flex;
}
.cart-total {
    padding: 5px;
    font-size: 16px;
    color: #ffffff;
}
.cart-total-num {
    padding: 5px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 900;
}
.product-grid .product-discount-label:hover {
    cursor: pointer;
}
.del-prodInCart{
    float: right;
    width: 19px;
    padding: 0px;
    position: relative;
    border-radius: 100%;
    background: #982827;
}
.no-data-text {
    margin: auto;
    padding: 20px;
}
.cart-qty {
    top: -16px !important;
    background: crimson !important;
    color: #fff !important;
    height: 18px !important;
    line-height: 18px !important;
    border-radius: 10px !important;
    display: inline-block !important;
    margin: 3px 0 0 !important;
    min-width: 18px !important;
    overflow: hidden !important;
    padding: 0 3px !important;
    text-align: center !important;
    white-space: normal !important;
    font-size: 9px !important;
    position: relative !important;
    left: -4px !important;
}
.obj-list{
    color:rgb(34, 34, 34);
}
.obj-list:hover{
    color:#f7255d;
}
.obj-row-list:hover{
    cursor: pointer;
}